import { WebGLRenderer } from 'three'
import { component } from 'bidello'
import settings from './settings'

class Renderer extends component(WebGLRenderer) {
  constructor() {
    super({
      // PowerPreference: 'high-performance',
      antialiasing: false,
    })
    this.setClearColor(0xffffff)
    this.setPixelRatio(settings.dpr)
  }

  onResize({ width, height }) {
    this.setSize(width, height)
  }
}

export default new Renderer()
