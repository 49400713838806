import bidello from 'bidello'

class Viewport {
  constructor() {
    this.width = this.calculateWidth()
    this.height = this.calculateHeight()
    this.ratio = this.width / this.height

    this.onResize = this.onResize.bind(this)
    this.onResize()

    window.addEventListener('resize', this.onResize)
  }

  // eslint-disable-next-line class-methods-use-this
  calculateWidth() {
    return window.innerWidth
  }

  // eslint-disable-next-line class-methods-use-this
  calculateHeight() {
    return window.innerHeight
  }

  onResize() {
    this.width = this.calculateWidth()
    this.height = this.calculateHeight()
    this.ratio = this.width / this.height

    bidello.trigger(
      { name: 'resize', fireAtStart: true },
      {
        width: this.width,
        height: this.height,
        ratio: this.ratio,
      }
    )
  }
}

export const viewport = new Viewport()
