import { component } from 'bidello'
import {
  Object3D,
  DoubleSide,
  Points,
  BufferGeometry,
  Float32BufferAttribute,
} from 'three'
import MagicShader from 'magicshader'
import camera from '../camera'
import { randomInt, randomFloat } from 'math-toolbox'
import trail from '../utils/trail'

const PALETTE = [
  [255, 97, 97],
  [209, 208, 32],
  [102, 206, 206],
  [49, 122, 249],
]

export default class extends component(Object3D) {
  init() {
    this.geometry = new BufferGeometry()
    const vertices = []
    const colors = []

    const COLS = 50
    const ROWS = 30

    for (let i = 0; i < COLS; i++) {
      for (let j = 0; j < ROWS; j++) {
        const offset = 0.04
        const [r, g, b] = PALETTE[randomInt(0, PALETTE.length - 1)]

        const rx = randomFloat(-0.005, 0.005)
        const ry = randomFloat(-0.005, 0.005)
        vertices.push(offset * i + rx, offset * j + ry, randomFloat(-0.1, 0.1))
        colors.push(r / 255, g / 255, b / 255)
      }
    }

    this.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(vertices, 3)
    )

    this.geometry.setAttribute('color', new Float32BufferAttribute(colors, 3))

    this.material = new MagicShader({
      vertexShader: /* Glsl */ `
        precision highp float;

        attribute vec3 position;
        attribute vec3 color;
        uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;
        uniform sampler2D uTrail;
        uniform float uTime;

        uniform float frequency; // ms({ value: 1.2, step: 0.001, range: [0.0, 50.0 ]})
        uniform float speed; // ms({ value: 0.56, step: 0.001, range: [0.0, 10.0 ]})
        uniform float force; // ms({ value: 0.55, step: 0.001, range: [0.0, 10.0 ]})
        uniform float uDistForce; // ms({ value: 0.6, step: 0.001, range: [0.0, 5.0]})
        uniform float uParabolak; // ms({ value: 1.2, step: 0.001, range: [0.0, 5.0]})
        uniform vec3 uOffset; // ms({ value: [-1, -0.5, 0.0], step: 0.001 })

        varying vec3 vColor;
        varying vec3 vPos;
        varying float vHover;

        float parabola( float x, float k )
        {
            return pow( 4.0*x*(1.0-x), k );
        }

        vec3 deformer(vec3 f) {
          float dist = length(f + uOffset);
          float forceDist = force;
          forceDist *= smoothstep(uDistForce, 0.0, dist);

          float curve = sin(frequency * dist - uTime * speed);
          curve = parabola((curve + 1.0) / 2.0, uParabolak);

          return vec3(f.xy,
            (f.z + curve) * forceDist
          );
        }

        void main() {
          vec4 clipSpace = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

          vec2 uv = ((clipSpace.xy / clipSpace.w) + 1.0) / 2.0;
          float hover = texture2D(uTrail, uv).r;

          vHover = hover;
          vColor = color;

          vec3 transformed = deformer(position);

          vec4 vTr = modelViewMatrix * vec4(transformed, 1.0);
          vPos = vTr.xyz;

          gl_Position = projectionMatrix * vTr;
          // gl_PointSize = 5.0 + hover * 40.0;
          gl_PointSize = mix(10.0, 3.0, smoothstep(0.0, -20.0, vPos.z)) + hover * 40.0;
        }
      `,
      fragmentShader: /* Glsl */ `
        precision highp float;

        varying vec3 vColor;
        varying float vHover;
        varying vec3 vPos;

        float aastep(float threshold, float value) {
          #ifdef GL_OES_standard_derivatives
            float afwidth = length(vec2(dFdx(value), dFdy(value))) * 0.70710678118654757;
            return smoothstep(threshold-afwidth, threshold+afwidth, value);
          #else
            return step(threshold, value);
          #endif
        }

        void main() {
          vec2 uv = gl_PointCoord;
          vec3 color = mix(vec3(1.0), vec3(vColor), vHover);
          float alpha = 1.0 - aastep(0.5, length(uv - .5));

          alpha *= mix(0.4, 1.0, vHover);
          alpha *= 1.0 - smoothstep(-5.0, -18.0, vPos.z);

          gl_FragColor = vec4(color, alpha);
        }
      `,
      extensions: {
        derivatives: true,
      },
      depthTest: false,
      transparent: true,
      uniforms: {
        uTime: { value: 0 },
        uTrail: { value: trail.fbo.target },
      },
    })

    this.mesh = new Points(this.geometry, this.material)
    this.add(this.mesh)
    this.mesh.frustumCulled = false

    // DEV
    // this.pivot = new Object3D()
    // this.pivot.name = 'pivot'
    // this.pivot.add(this.mesh)
    // this.add(this.pivot)
  }

  onResize() {
    const size = Math.max(camera.unit.width, camera.unit.height) * 1.3

    this.mesh.scale.set(size, size, 1)
    this.mesh.position.x = -size
    this.mesh.position.y = -size / 2
    this.mesh.position.y += 0.53
  }

  onRaf({ delta }) {
    this.material.uniforms.uTrail.value = trail.fbo.target
    this.material.uniforms.uTime.value += delta
  }
}
