import * as helpers from './bidello'
import renderer from './renderer'
import camera from './camera'
import scene from './scene'
import { component } from 'bidello'
import settings from './settings'
// import postfx from './postfx/postfx'
import assets from './assets'
import MagicShader, { gui } from 'magicshader'
gui.destroy()

export default class Site extends component() {
  // eslint-disable-next-line class-methods-use-this
  init() {
    this.scene = scene
    this.renderer = renderer
    assets.load()
  }

  // eslint-disable-next-line class-methods-use-this
  onRaf() {
    renderer.render(scene, camera)
    // postfx.render(scene, camera)
  }
}
