import { component } from 'bidello'
import { Object3D, PlaneBufferGeometry, Mesh, Vector3 } from 'three'
import MagicShader from 'magicshader'
import camera from '../camera'
import { gsap } from 'gsap'

export default class extends component(Object3D) {
  init() {
    const DENSITY = 500
    this.geometry = new PlaneBufferGeometry(1, 1, DENSITY, DENSITY)
    this.material = new MagicShader({
      // Wireframe: true,
      vertexShader: /* Glsl */ `
        precision highp float;

        attribute vec3 normal;
        attribute vec3 position;

        uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;
        uniform mat3 normalMatrix;
        uniform float uTime;

        uniform float frequency; // ms({ value: 29.0, step: 0.001, range: [0.0, 50.0 ]})
        uniform float speed; // ms({ value: 0.56, step: 0.001, range: [0.0, 10.0 ]})
        uniform float force; // ms({ value: 0.337, step: 0.001, range: [0.0, 10.0 ]})
        uniform float uDistForce; // ms({ value: 0.4, step: 0.001, range: [0.0, 5.0]})
        uniform float uParabolak; // ms({ value: 1.2, step: 0.001, range: [0.0, 5.0]})
        uniform vec3 uOffset;

        varying vec3 vNormal;
        varying vec3 vPos;

        float parabola( float x, float k )
        {
            return pow( 4.0*x*(1.0-x), k );
        }

        vec3 deformer(vec3 f) {
          float dist = length(f + uOffset);
          float forceDist = force;
          forceDist *= smoothstep(uDistForce, 0.0, dist);

          float curve = sin(frequency * dist - uTime * speed);
          curve = parabola((curve + 1.0) / 2.0, uParabolak);

          return vec3(f.xy,
            (f.z + curve) * forceDist
          );
        }

        void main() {
          vec3 original = position;
          vPos = original;

          vec3 transformed = deformer(original);

          float offset = 0.0001;
          vec3 n1 = deformer(original + vec3(offset, 0.0, 0.0));
          vec3 n2 = deformer(original + vec3(0.0, offset, 0.0));
          vec3 tangent = n1 - transformed;
          vec3 bitangent = n2 - transformed;

          vNormal = normalMatrix * normalize(cross(tangent, bitangent));

          gl_Position = projectionMatrix * modelViewMatrix * vec4(transformed, 1.0);
        }
      `,
      fragmentShader: /* Glsl */ `
        precision highp float;
        varying vec3 vNormal;
        varying vec3 vPos;

        uniform vec3 uColor; // ms({ value: '#317AF9' })
        uniform vec3 uDir; // ms({ value: [0.2, 0.29, 1.0] })
        uniform vec3 uOffset;

        uniform bool uDraw;

        void main() {
          vec3 color = uColor;

          if (uDraw) {
            vec3 normal = normalize(vNormal);

            float f = max(dot(normal,normalize(uDir)), 0.0);
            color *= f;

            // float f2 = max(dot(normal,normalize(vec3(-0.2, 0.3, 1.0))), 0.0);
            // color = mix(color, vec3(1.0), 1.0 - f2);

            color = mix(color, uColor, smoothstep(0.03, 0.0, length(vPos + uOffset)));
          }

          gl_FragColor = vec4(color, 1.0);
        }
      `,
      uniforms: {
        uTime: { value: 0 },
        uDraw: { value: true },
        uOffset: { value: new Vector3(0.0, 0.1, 0.0) },
      },
    })

    this.mesh = new Mesh(this.geometry, this.material)
    this.add(this.mesh)
  }

  onResize() {
    const size = Math.max(camera.unit.width, camera.unit.height) * 1.3
    this.scale.set(size, size, 1)
  }

  onRaf({ delta }) {
    this.material.uniforms.uTime.value += delta
  }
}
