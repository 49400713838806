import { Color, Scene } from 'three'
import { component } from 'bidello'
import camera from './camera'
import Wave from './wave/wave'
import Grid from './grid/grid'

class Stage extends component(Scene) {
  init() {
    this.background = new Color(0xffffff)
    this.wave = new Wave()
    this.grid = new Grid()
    this.add(this.wave)
    this.add(this.grid)
    this.add(camera)
  }
}

export default new Stage()
